<!-- 商务信息 > 商务编辑 > 商务附件(组件) -->
<template>
  <div>
      <UploadList v-model="uploadData" fileCode="user_fj" :staffId="manageId" :disabled="true"></UploadList>
  </div>
</template>
<script>
export default {
  components: {
      UploadList: () => import("@/components/upload/ContractList.vue"),
  },
  inject: ['re'],
  props: {
      manageId: {
      },
      file_Data: {
      },
  },
  model: {
      prop: 'value',
      event: 'changed'
  },
  data() {
      return {
          loading: false,
          saveloading: false,
          uploadData: []
      };
  },
  watch:{
      manageId:{
          immediate:true,
          handler: function (a){
              if(a){

              }
          }
      },
      file_Data:{
          handler: function (a){
              if(a){
                this.uploadData = a;
              }
          }
      },
      uploadData:{
          immediate:true,//初始化立即执行
          deep: true,//对象深度监测
          handler: function (newVal){
            let arr = [];
            if(this.uploadData.length > 0){
              for (let i = 0; i < this.uploadData.length; i++) {
                const e = this.uploadData[i];
                e.fileName = e.attachmentName
                e.filePath = e.attachmentPath
                e.businessId = e.staffId
                arr.push(e)
              }
            }
              this.$emit('changed', arr)
          }
      },

  },
  created() {

  },
  methods: {
  },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

  .el-table {
      height:calc(100vh - 347px);
  }
</style>